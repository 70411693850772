/** @jsx jsx */
import { jsx, Flex, Box, Context } from 'theme-ui';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTranslate } from 'react-localize-redux';

import Layout from '../components/layout';
import Container from '../components/Container';
import { getProducts, loadProductsForCountry } from '../state/catalog';
import { PAGE, buySeriesShipment, resetParcelFlow, showParcel, abroadEnabled } from '../state/parcelFlow';
import Contents from '../parcelFlow/Contents';
import ContentsAland from '../parcelFlow/ContentsAland';
import SelectBusLine from '../parcelFlow/SelectBusLine';
import DestinationCountry from '../parcelFlow/DestinationCountry';
import ExtraServices from '../parcelFlow/ExtraServices';
import DeliveryOptions from '../parcelFlow/DeliveryOptions';
import ParcelProduct from '../parcelFlow/ParcelProduct';
import ParcelType from '../parcelFlow/ParcelType';
import PickupPoint from '../parcelFlow/PickupPoint';
import ProgressIndicator from '../parcelFlow/ProgressIndicator';
import Recipient from '../parcelFlow/Recipient';
import RePackPoint from '../parcelFlow/RePackPoint';
import { isBrowser } from '../utils';
import { HelpIcon } from '../components/Icon';
import Tooltip from '../components/Tooltip';
import * as analytics from '../utils/analytics';
import ExpressPackageBackupAddress from '../parcelFlow/ExpressPackageBackupAddress';

let latestInstance = 0;

class BuyParcel extends Component {
  constructor(props) {
    super(props);
    latestInstance++;
    this.instanceNo = latestInstance;

    const { products } = props.catalog;
    if (props.country == 'FI') {
      if (isBrowser && (!Array.isArray(products) || products.length === 0)) {
        this.props.getProducts();
      }
    } else {
      const products = props.catalog.country[props.country] || [];
      if (products.length === 0) {
        this.props.loadProductsForCountry(props.country, false);
      }
    }

    this.props.buySeriesShipment(false);

    if (isBrowser && props.location && props.location.search) {
      const productId = new URLSearchParams(props.location.search).get('productId');
      const size = new URLSearchParams(props.location.search).get('size');
      const country = new URLSearchParams(props.location.search).get('country');
      if (size || country || productId) {
        this.props.showParcel(size || productId, country || 'FI');
      }
    }
  }

  componentWillUnmount() {
    // if user changes language, new component is constructed before this method is called
    if (this.instanceNo === latestInstance) {
      this.props.resetParcelFlow();
    }
  }

  render() {
    analytics.setPageCategory(this, 'paketit');
    const { page, translate } = this.props;
    const { paths } = this.props.pageContext;
    if (!isBrowser) {
      // no prerendering: prevent flash when navigate directly past weight selection pages
      return (
        <Layout title={translate('buyParcel.title')} paths={paths} locale={this.props.pageContext.locale || 'en'}>
          <Container />
        </Layout>
      );
    }

    const locale = this.props.pageContext.locale || 'en';
    return (
      <Layout
        title={translate('buyParcel.title')}
        paths={paths}
        locale={locale}
        checkShopDisturbance={true}
        footer={false}
        showNavigation={false}
      >
        <Container>
          <Flex
            sx={{
              flexDirection: ['column', null, null, 'row'],
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                top: 72,
                width: ['auto', null, null, 160],
                order: [0, null, null, 1],
                mt: [0, null, null, 96],
                mb: [2, null, null, 0],
                ml: [-3, null, null, 3],
                mr: [-3, null, null, 0],
              }}
            >
              <ProgressIndicator />
            </Box>
            <Box sx={{ flex: 1 }}>
              {(() => {
                switch (page) {
                  case PAGE.PARCEL_TYPE:
                    return <ParcelType />;
                  case PAGE.SELECT_COUNTRY:
                    return <DestinationCountry />;
                  case PAGE.SELECT_PRODUCT:
                    return <ParcelProduct />;
                  case PAGE.SELECT_EXTRAS:
                    return <ExtraServices />;
                  case PAGE.SELECT_DELIVERY:
                    return <DeliveryOptions />;
                  case PAGE.RECIPIENT:
                    return <Recipient />;
                  case PAGE.PICKUP_POINT:
                    return <PickupPoint />;
                  case PAGE.EMPTY_WAIT_PAGE:
                    return null;
                  case PAGE.SELECT_REPACK_OFFICE:
                    return <RePackPoint />;
                  case PAGE.ASK_CONTENTS:
                    return <Contents />;
                  case PAGE.ASK_CONTENTS_ALAND:
                    return <ContentsAland />;
                  case PAGE.SELECT_BUS_LINE:
                    return <SelectBusLine />;
                  case PAGE.EXPRESS_PACKAGE_BACKUP_ADDRESS:
                    return <ExpressPackageBackupAddress />;
                  default:
                    return <h1>Virhetilanne {page}</h1>;
                }
              })()}
            </Box>
          </Flex>
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  catalog: state.catalog || {},
  country: state.parcelFlow.country || 'FI',
  page: (state.parcelFlow || {}).page,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      buySeriesShipment,
      getProducts,
      loadProductsForCountry,
      resetParcelFlow,
      showParcel,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BuyParcel);
